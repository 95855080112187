<template>
  <div>
    <article class="page product">
      <div class="content-con">
        <section class="path">
          <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb d-inline-flex"
          >
            <el-breadcrumb-item :to="{ path: '/Index' }"
              >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item
              v-for="(item, index) in CategoryBreadcrumb.slice().reverse()"
              :to="{ path: '/Category?prodCateId=' + item.Id }"
              :key="index"
              >{{ item.Name }}</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{ Product.Title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </section>
        <article class="product">
          <section class="product-photo">
            <figure class="photo-view">
              <img :src="GetImageUrl(Product.MainImage, 360)" alt="" />
            </figure>
          </section>
          <!--商品圖片-->
          <!--/商品圖片-->
          <!--商品內容-->
          <section class="product-info">
            <div class="info-con">
              <p class="info-category">
                {{ CategoryBreadcrumb[0].Name }}
              </p>
              <h2 class="info-tit">{{ Product.Title }}</h2>
              <p class="info-intro">{{ Product.SubTitle }}</p>
              <p class="info-price">
                <span class="price-orignal">{{ Product.OriginalPrice }}</span
                ><span class="price-discount">{{ Product.Price }}</span>
              </p>
              <section class="forms-basic info-spec">
                <el-select v-model="SelectedProductSpecKey" class="inp-block">
                  <el-option
                    v-for="(item, index) in ProductSpec"
                    :key="index"
                    :value="index"
                    :label="item.Name"
                  ></el-option>
                </el-select>
                <div class="amount">
                  <p>
                    <el-select
                      v-model="SelectedBuyQuantity"
                      placeholder="1"
                      class="inp-block"
                    >
                      <el-option
                        v-for="number in GetCurrentQuantity()"
                        :key="number"
                        :label="number"
                        :value="number"
                      >
                      </el-option>
                    </el-select>
                  </p>
                </div>
              </section>
              <!--<span class="newnew">
                <img src="/assets/img/new.gif"/>扩大服务！欢迎使用USDT、ETH以及BTC付款！<img src="/assets/img/new.gif"/>
              </span>-->
              <section class="info-action">
                <button
                  type="button"
                  class="btn-active"
                  v-on:click="PutToCart('cart')"
                >
                  加入购物车
                </button>
                <button
                  type="button"
                  class="btn-action"
                  v-on:click="PutToCart('buynow')"
                >
                  立即购买
                </button>
              </section>
            </div>
          </section>
          <!--/商品內容-->
          <section class="product-intro">
            <h3><em>商品介绍</em><span></span></h3>
            <div class="intro-detial" v-html="decoder(Product.Desc)"></div>
          </section>
        </article>
      </div>
    </article>
  </div>
</template>
<style scoped>
.newnew {
  display: block;
  text-align: center;
}

.newnew > img {
  display: inline-block;
  top: 2px;
  position: relative;
  margin: auto 0.5rem;
}
</style>
<script>
import {
  GetProductById,
  GetProductSpecByProdId,
  CheckIsProductCanBuy,
  GetBreadCrumbByProductId,
} from "@/service/api";

export default {
  name: "Product",
  data() {
    return {
      Product: [],
      ProductSpec: [],
      CategoryBreadcrumb: [],
      SelectedProductSpecKey: 0,
      SelectedBuyQuantity: 0,
    };
  },
  beforeMount: function () {
    GetProductById(this.$route.query.productId)
      .then((resp) => {
        this.Product = resp.data;
      })
      .catch((data) => {
        if (data.status === 401) alert("Error: token is not found;");
        if (data.status === 403) alert("Error: authentication failed;");
      });

    GetProductSpecByProdId(this.$route.query.productId)
      .then((resp) => {
        this.ProductSpec = resp.data;
      })
      .catch((data) => {
        if (data.status === 401) alert("Error: token is not found;");
        if (data.status === 403) alert("Error: authentication failed;");
      });

    GetBreadCrumbByProductId(this.$route.query.productId)
      .then((resp) => {
        this.CategoryBreadcrumb = resp.data;
      })
      .catch((data) => {
        if (data.status === 401) alert("Error: token is not found;");
        if (data.status === 403) alert("Error: authentication failed;");
      });
    $(".banner-cover").addClass("_lvThr");
    $("#ban1").css("background-image", "url(assets/img/banner_lvThr1.jpg)");
  },
  watch: {
    Product: function () {
      this.$parent.SetWindowTitle(this.Product.Title);
    },
  },
  methods: {
    SpecSelected: function (specId) {
      this.SelectedProductSpecKey = specId;
      this.SelectedBuyQuantity = 0;
    },
    GetImageUrl: function (imgId, size) {
      var d = new Date();
      var n = d.getTime();
      return (
        "https://n2imageservice.azurewebsites.net/api/images/shopmall/" +
        imgId +
        "/" +
        size +
        "/0?" +
        n
      );
    },
    GetCurrentQuantity: function () {
      if (this.SelectedProductSpecKey == -1) {
        return 0;
      } else {
        return this.ProductSpec[this.SelectedProductSpecKey].Quantity;
      }
    },
    decoder(str) {
      var textArea = document.createElement("textarea");
      textArea.innerHTML = str;
      return textArea.value;
    },
    RunIsProductCanBuy(productSpecId, qvt) {
      CheckIsProductCanBuy(productSpecId, qvt)
        .then((resp) => {
          return resp.data;
        })
        .catch((data) => {
          if (data.status === 401) alert("Error: token is not found;");
          if (data.status === 403) alert("Error: authentication failed;");
        });
    },
    PutToCart: function (type) {
      if (this.SelectedProductSpecKey != -1) {
        if (this.SelectedBuyQuantity != 0) {
          CheckIsProductCanBuy(
            this.ProductSpec[this.SelectedProductSpecKey].Id,
            this.SelectedBuyQuantity
          ).then((result) => {
            if (result.data) {
              var itemToCart = {
                ProductId: this.Product.Id,
                ProductSpecId: this.ProductSpec[this.SelectedProductSpecKey].Id,
                Quantity: this.SelectedBuyQuantity,
              };
              var forCartId =
                "cart_" +
                this.Product.Id +
                this.ProductSpec[this.SelectedProductSpecKey].Id;
              var oldItem = localStorage.getItem(forCartId);
              if (oldItem != null) {
                oldItem = JSON.parse(oldItem);
                itemToCart.Quantity =
                  parseInt(itemToCart.Quantity, 10) +
                  parseInt(oldItem.Quantity, 10);
              }
              CheckIsProductCanBuy(
                this.ProductSpec[this.SelectedProductSpecKey].Id,
                itemToCart.Quantity
              ).then((result) => {
                if (result.data) {
                  localStorage.setItem(forCartId, JSON.stringify(itemToCart));
                  this.$parent.RefreshCart();
                  if (type == "buynow") window.location.href = "cart";
                } else {
                  alert("库存不足！");
                }
              });
            } else {
              alert("库存不足！");
            }
          });
        } else {
          alert("请选择数量！");
        }
      } else {
        alert("请选择规格！");
      }
    },
  },
};
</script>